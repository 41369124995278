require.context('../assets', true)
import '../css/application';
import 'bootstrap-icons/font/bootstrap-icons.css'

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import flatpickr from 'flatpickr';

Rails.start();
Turbolinks.start();
// require('@rails/activestorage').start()
// require('channels');

window.$ = require('jquery');
import 'bootstrap';
// OPTIMIZE: dont include entire 'bootstrap'
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/util';
import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/js/v4-shims';

$(document).on('turbolinks:load', () => {
  setTimeout(() => {
    $('.flash-msg').remove();
    $('#flash-modal').modal('hide');
  }, 3000);

  flatpickr('.date-picker', { enableTime: true, time_24hr: true });
  flatpickr('.date-picker-min', { enableTime: true, time_24hr: true, minDate: new Date() });
  flatpickr('.date-picker-activity', { enableTime: true, time_24hr: true, minDate: new Date().fp_incr(-14), maxDate: new Date() });

  $('body').on('click', '.delete-row-js', function(e) {
    e.preventDefault();
    $($(this).data('remove-row-id')).remove();
  })
});
